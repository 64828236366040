<template>
  <b-dropdown id="locale-dropdown" right no-caret>
    <template #button-content>
      <span class="button--content font-weight-light text-uppercase">
        {{ locale }}
      </span>
    </template>
    <template v-for="(locale, index) in localeOptions">
      <b-dropdown-item
        :key="index"
        class="font-weight-light"
        :active="isActiveLocale(locale.value)"
        @click="!isActiveLocale(locale.value) && changeLocale(locale.value)"
      >
        {{ locale.text }}
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>

<script>
export default {
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    localeOptions() {
      return this.$i18n.availableLocales.map(locale => ({
        value: locale,
        text: this.getLocaleText(locale)
      }));
    }
  },
  methods: {
    getLocaleText(locale) {
      switch (locale) {
        case "it":
          return "Italian";
        case "en":
          return "English";
        case "fr":
          return "French";
        case "es":
          return "Spanish";
        case "de":
          return "German";
        case "zh":
          return "Chinese"
        default:
          return locale;
      }
    },
    isActiveLocale(locale) {
      return this.$i18n.locale === locale;
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
      const to = this.$router.resolve({ params: { lang: this.$i18n.locale } });
      this.$router.replace(to.location);
    }
  }
};
</script>

<style lang="scss" scoped>
#locale-dropdown {
  border-left: 1px solid white;
  padding-left: 8px;
  /deep/ {
    button {
      width: 60px;
      color: white;
      text-align: start;
      box-shadow: unset;
      flex: unset;
      border-radius: unset;
      background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='white' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        no-repeat right 0.75rem center/8px 10px;
      .button--content {
        font-size: 14px;
      }
    }
    .dropdown-menu {
      min-width: unset;
    }
  }
}

@media screen and (max-width: 991px) {
  #locale-dropdown {
    border-left: unset;
    padding-left: 0;
    /deep/ {
      button {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
</style>
