<template>
  <b-list-group
    v-b-hover="handleHover"
    class="bg-gray-primary rounded-0 collapsed-menu vh-100 pb-5"
    :class="{ 'expanded-menu': isMenuExpanded }"
  >
    <b-list-group-item
      class="rounded-0 border-0 clickable mt-2 px-3"
      :class="{ 'mb-4': !isExpandItemVisible }"
      @click="goToDashboard"
    >
      <b-row class="no-gutters text-white flex-nowrap">
        <b-col class="col-auto">
          <img
            width="28px"
            src="./../assets/general-icons/Icon-Emotiva-2020.svg"
            fluid
            alt="logo"
          />
        </b-col>
        <b-col v-if="isMenuExpanded" class="ml-3 h5 mb-0">
          {{ $t("sideMenu.emotiva") }}
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item
      v-b-visible="handleExpandItemVisibility"
      class="rounded-0 border-0 clickable d-lg-none"
      :class="{ 'mt-4': isExpandItemVisible }"
      @click="isExpandItemActive = !isExpandItemActive"
    >
      <b-icon icon="list" variant="white" font-scale="1.0"></b-icon>
    </b-list-group-item>
    <b-list-group-item
      class="rounded-0 border-0 clickable"
      :class="{ 'bg-color-light': isRouteActive('Account') }"
      @click="goToPage('Account')"
      v-if="isAllowedAccountRoute"
    >
      <b-row
        data-v-step="10"
        class="no-gutters text-white flex-nowrap align-items-end menu__item-container"
      >
        <b-col class="col-auto">
          <img
            src="./../assets/general-icons/user-alt-7.svg"
            class="menu__icon"
            :class="{ 'active__menu-icon': isRouteActive('Account') }"
          />
        </b-col>
        <b-col
          v-if="isMenuExpanded"
          class="menu__text-item"
          :class="{ menu__text__active: isRouteActive('Account') }"
        >
          {{ $t("sideMenu.account") }}
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item
      class="rounded-0 border-0 clickable"
      :class="{
        'bg-color-light': isRouteActive('Branches'),
      }"
      @click="goToPage('Branches')"
      v-if="isAllowedBranchesRoute"
    >
      <b-row
        data-v-step="10"
        class="no-gutters text-white flex-nowrap align-items-end menu__item-container"
      >
        <b-col class="col-auto">
          <img
            src="./../assets/general-icons/branches.svg"
            class="menu__icon"
            :class="{ 'active__menu-icon': isRouteActive('Branches') }"
          />
        </b-col>
        <b-col
          v-if="isMenuExpanded"
          class="menu__text-item"
          :class="{ menu__text__active: isRouteActive('Branches') }"
        >
          {{ $t("sideMenu.branches") }}
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item
      class="rounded-0 border-0 clickable"
      :class="{
        'bg-color-light': isRouteActive('Users'),
      }"
      @click="goToPage('Users')"
      v-if="isAllowedUsersRoute"
    >
      <b-row
        data-v-step="10"
        class="no-gutters text-white flex-nowrap align-items-end menu__item-container"
      >
        <b-col class="col-auto">
          <img
            src="./../assets/general-icons/users.svg"
            class="menu__icon"
            :class="{ 'active__menu-icon': isRouteActive('Users') }"
          />
        </b-col>
        <b-col
          v-if="isMenuExpanded"
          class="menu__text-item"
          :class="{ menu__text__active: isRouteActive('Users') }"
        >
          {{ $t("sideMenu.users") }}
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item
      class="rounded-0 border-0 clickable"
      :class="{ 'bg-color-light': isRouteActive('Projects') }"
      @click="goToPage('Projects')"
    >
      <b-row
        data-v-step="11"
        class="no-gutters text-white flex-nowrap align-items-end menu__item-container"
      >
        <b-col class="col-auto">
          <img
            src="./../assets/menu-icons/MENU_chart-line.svg"
            class="menu__icon"
            :class="{ 'active__menu-icon': isRouteActive('Projects') }"
          />
        </b-col>
        <b-col
          v-if="isMenuExpanded"
          class="menu__text-item"
          :class="{ menu__text__active: isRouteActive('Projects') }"
        >
          {{ $t("sideMenu.projects") }}
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item
      class="rounded-0 border-0 clickable"
      :class="{ 'bg-color-light': isRouteActive('Billing') }"
      @click="goToPage('Billing')"
      v-if="isAllowedBillingRoute"
    >
      <b-row
        data-v-step="12"
        class="no-gutters text-white flex-nowrap align-items-end menu__item-container"
      >
        <b-col class="col-auto">
          <img
            src="./../assets/menu-icons/MENU_credit-card.svg"
            class="menu__icon"
            :class="{ 'active__menu-icon': isRouteActive('Billing') }"
          />
        </b-col>
        <b-col
          v-if="isMenuExpanded"
          class="menu__text-item"
          :class="{ menu__text__active: isRouteActive('Billing') }"
        >
          {{ $t("sideMenu.billing") }}
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item
      class="rounded-0 border-0 clickable"
      :class="{ 'bg-color-light': isRouteActive('Usage') }"
      @click="goToPage('Usage')"
      v-if="isAllowedUsageRoute"
    >
      <b-row
        data-v-step="13"
        class="no-gutters text-white flex-nowrap align-items-end menu__item-container"
      >
        <b-col class="col-auto">
          <img
            src="./../assets/menu-icons/MENU_usage.svg"
            class="menu__icon"
            :class="{ 'active__menu-icon': isRouteActive('Usage') }"
          />
        </b-col>
        <b-col
          v-if="isMenuExpanded"
          class="menu__text-item"
          :class="{ menu__text__active: isRouteActive('Usage') }"
        >
          {{ $t("sideMenu.usage") }}
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item
      class="rounded-0 border-0 margin__t_3 clickable"
      @click="openExternalPage(supportLinks)"
    >
      <b-row
        data-v-step="100"
        class="no-gutters text-white flex-nowrap align-items-end menu__item-container"
      >
        <b-col class="col-auto">
          <img
            src="./../assets/menu-icons/MENU_support-faq.svg"
            class="menu__icon"
          />
        </b-col>
        <b-col v-if="isMenuExpanded" class="menu__text-item">
          {{ $t("sideMenu.help") }}
        </b-col>
      </b-row>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import Utils from "./../Utils";
export default {
  created() {},
  data() {
    return {
      isHovered: false,
      isExpandItemActive: false,
      isExpandItemVisible: false,
      supportLinks: {
        linkEn: "https://emotiva.it/en/empower-getting-started/",
        linkIt: "https://emotiva.it/empower-per-iniziare/",
      },
    };
  },
  watch: {
    isExpandItemVisible: {
      handler: function () {
        // Close menu on window resize
        if (this.isExpandItemVisible) this.isExpandItemActive = false;
        else this.isHovered = false;
      },
      immediate: false,
    },
  },
  computed: {
    isTourActive() {
      return this.$store.getters["settings/isTourActive"];
    },
    isMenuExpanded() {
      if (this.isTourActive) return true;

      return this.isExpandItemVisible
        ? this.isExpandItemActive
        : this.isHovered;
    },
    isClientCustom() {
      return (
        this.$store.getters["auth/getUserType"] === Utils.USER_TYPE.CUSTOMER
      );
    },
    isAllowedAccountRoute() {
      const isMultiUser = this.$store.getters["auth/isMultiUser"];
      const userType = this.$store.getters["auth/getUserType"];
      const userSubType = this.$store.getters["auth/getUserSubType"];

      if (userType === "custom" && isMultiUser) {
        // allow only admins to access account route
        if (userSubType === "admin") {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    isAllowedBranchesRoute() {
      const isMultiUser = this.$store.getters["auth/isMultiUser"];
      const userType = this.$store.getters["auth/getUserType"];
      const userSubType = this.$store.getters["auth/getUserSubType"];

      // only custom + multiuser clients can access route
      if (userType === "custom" && isMultiUser) {
        // allow only admins to access branches route
        if (userSubType === "admin") {
          return true;
        } else {
          return false;
        }
      }

      // return false for all other client types
      return false;
    },
    isAllowedUsersRoute() {
      const isMultiUser = this.$store.getters["auth/isMultiUser"];
      const userType = this.$store.getters["auth/getUserType"];
      const userSubType = this.$store.getters["auth/getUserSubType"];

      // only custom + multiuser clients can access route
      if (userType === "custom" && isMultiUser) {
        // allow everyone except researchers to access route
        if (
          userSubType === "admin" ||
          userSubType === "branch" ||
          userSubType === "collaborator"
        ) {
          return true;
        } else {
          return false;
        }
      }

      // return false for all other client types
      return false;
    },
    isAllowedBillingRoute() {
      const isMultiUser = this.$store.getters["auth/isMultiUser"];
      const userType = this.$store.getters["auth/getUserType"];
      const userSubType = this.$store.getters["auth/getUserSubType"];

      // only custom clients can access route
      if (userType === "custom") {
        // prevent access to branch, collaborator, researcher subType
        if (
          isMultiUser &&
          (userSubType === "branch" ||
            userSubType === "collaborator" ||
            userSubType === "researcher")
        ) {
          return false;
        }
        // all other custom clients can access route
        return true;
      }
      // return false for all other client types
      return false;
    },
    isAllowedUsageRoute() {
      const isMultiUser = this.$store.getters["auth/isMultiUser"];
      const userType = this.$store.getters["auth/getUserType"];
      const userSubType = this.$store.getters["auth/getUserSubType"];

      // only custom clients can access route
      if (userType === "custom") {
        // prevent access to branch, collaborator, researcher subType
        if (
          isMultiUser &&
          (userSubType === "branch" ||
            userSubType === "collaborator" ||
            userSubType === "researcher")
        ) {
          return false;
        }
        // all other custom clients can access route
        return true;
      }
      // return false for all other client types
      return false;
    },
  },
  methods: {
    handleHover(hovered) {
      this.isHovered = hovered;
    },
    handleExpandItemVisibility(val) {
      this.isExpandItemVisible = val;
    },
    goToDashboard() {
      if (this.$route.name !== "Dashboard" && this.$route.name !== "Login") {
        this.isHovered = false;
        this.isExpandItemActive = false;
        this.$router.push({ name: "Dashboard" });
      }
    },
    goToPage(routeName) {
      if (this.$route.name !== routeName) {
        this.isHovered = false;
        this.isExpandItemActive = false;
        this.$router.push({ name: routeName });
      }
    },
    isRouteActive(routeName) {
      return this.$route.name === routeName;
    },
    openExternalPage(linkItem) {
      window.open(
        Utils.getExternalLinkByLang({
          linkEn: linkItem.linkEn,
          linkIt: linkItem.linkIt,
        }),
        "_blank"
      );
    },
  },
};
</script>

<style scoped>
.collapsed-menu {
  transition: width 0.5s ease;
  /* position: fixed; */
  /* height: 100vh; */
  z-index: 3;
  width: 60px;
  overflow-x: hidden;
  overflow-y: auto;
}
.expanded-menu {
  transition: width 0.5s ease;
  width: 240px;
}
.bg-gray-primary {
  background-color: #3f3f3f;
}
.list-group-item {
  background-color: #3f3f3f;
}
.menu__text-item {
  font-size: 14px;
  font-weight: 400;
  margin-left: 30px;
}
.menu__text__active {
  color: #3f3f3f;
  font-weight: 700;
}
.bg-color-light {
  background-color: #f8f9fa;
}
.menu__icon {
  width: 14px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(43deg)
    brightness(102%) contrast(101%);
}
.active__menu-icon {
  filter: invert(0%) sepia(85%) saturate(7500%) hue-rotate(173deg)
    brightness(87%) contrast(113%);
}

.margin__t_3 {
  margin-top: 34px;
}
</style>
